<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改站点信息' : '添加站点'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="站点类型" prop="type">
        <el-select
          v-model="modalData.type"
          placeholder="请选择站点类型"
          filterable
        >
          <el-option
            v-for="(item, index) in this.const.STATION_TYPE"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="点位" prop="pointId">
        <el-select
          v-model="modalData.pointId"
          placeholder="请选择点位"
          filterable
        >
          <el-option
            v-for="(item, index) in pointList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="站点编号" prop="stationNum">
        <el-input
          v-model="modalData.stationNum"
          placeholder="请输入站点编号"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="柜子名称：" prop="stationName">
        <el-input
          v-model="modalData.stationName"
          placeholder="请输入柜子名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="柜子macId:"
        prop="stationMacId"
        v-if="modalData.type !== this.const.STATION_TYPE_CODE.OFFLINE"
      >
        <el-input
          v-model="modalData.stationMacId"
          placeholder="请输入柜子macId"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="可存电池数：" prop="num">
        <el-input
          v-model="modalData.num"
          placeholder="请输入可存放电池数量"
          :maxLength="50"
        ></el-input>
      </el-form-item>


      <el-form-item label="预留充电仓位：" prop="reserveDoor">
        <el-input
          v-model="modalData.reserveDoor"
          placeholder="请输入预留充电仓位"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="地区：" prop="areaArr">
        <el-cascader
          v-model="modalData.areaArr"
          placeholder="请选择代理地区"
          :options="areasList"
          @change="handleChange"
          :props="{ value: 'name', label: 'name' }"
          filterable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="详细地址：" prop="address">
        <el-input
          v-model="modalData.address"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="经度：" prop="longitude">
        <el-input
          v-model="modalData.longitude"
          placeholder="请输入经度例如： 113.529525"
        ></el-input>
      </el-form-item>

      <el-form-item label="纬度：" prop="latitude">
        <el-input
          v-model="modalData.latitude"
          placeholder="请输入纬度例如： 22.772146"
        ></el-input>
      </el-form-item>

      <el-form-item label="门店照片：" prop="stationImg">
        <UploadImg
          :defaultUrl="
            modalData.stationImg ? downloadUrl + modalData.stationImg : ''
          "
          action="/web/admin/file/upload"
          @success="fileUploadSuccess"
        ></UploadImg>
      </el-form-item>

    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
import { downloadUrl } from "@/config/env";
import { stationDetail,fetchPointAll } from "@/api/station";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalType", "agentList"],
  data() {
    return {
      areasList: areas,
      modalData: {},
      downloadUrl: downloadUrl,
      pointList: [],
      
      formName: "form",
      ruleValidate: {
        type: [
          {
            required: true,
            message: "站点类型不能为空",
            trigger: "change",
          },
        ],
        stationName: [
          {
            required: true,
            message: "站点名称不能为空",
            trigger: "change",
          },
        ],
        num: [
          {
            required: true,
            message: "可存放数不能为空",
            trigger: "change",
          },
        ],

        areaArr: [
          {
            required: true,
            message: "地区不能为空",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "详细地址不能为空",
            trigger: "change",
          },
        ],
        stationImg: [
          {
            required: true,
            message: "站点照片不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted(){
    this.getPointAll();
  },
  methods: {
    getPointAll(){
      fetchPointAll().then(res=>{
        this.pointList = res.data.data || [];
      })
    },
    //文件上传成功
    fileUploadSuccess(res) {
      this.modalData.stationImg = res;
    },
    changeSign(time) {
      if (!this.validatenull(time)) {
        this.defaultTime = time[1];
      }
    },
    // /web/admin/station/info?stationMacId=EB10122000123
    showModal(id) {
      if (!this.validatenull(id)) {
        stationDetail({ stationMacId: id }).then((res) => {
          this.modalData = res.data.data || {};
        });
      }

      this.isShow = true;
      if (this.$refs[this.formName]) {
        // 清除校验
        this.$nextTick(() => {
          this.$refs[this.formName].clearValidate();
        });
      }
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);

        // 选择的是线下网点 删除 macid
        if (obj.type === this.const.STATION_TYPE_CODE.POINT) {
          obj.stationMacId = "";
        }

        obj.adminId = this.$store.state.user.userInfo.id;
        obj.password = "123456";

        if (!this.validatenull(obj.areaArr)) {
          obj.province = obj.areaArr[0] || "";
          obj.city = obj.areaArr[1] || "";
          obj.area = obj.areaArr[2] || "";
        }
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
